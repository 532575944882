import { useState } from "react";
import SwiperCore from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, type SwiperClass } from "swiper/react";
import { ExternalLink } from "~/components/UI/ExternalLink/ExternalLink";
import { disableMotion } from "~/config";
import { testimonials, type TestimonialWithSlug } from "./content/testimonials";

import "./pagination.css";

SwiperCore.use([Pagination, Autoplay]);

export const TestimonialsSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper: SwiperClass) => {
    const realIndex = swiper.realIndex;
    setActiveIndex(realIndex);

    /*
     * Add "completed" class to all bullets before the active one.
     * This is done to show the progress of the slider instead of animating the active bullet only.
     */
    const bullets = document.querySelectorAll(".swiper-pagination-bullet");
    bullets.forEach((bullet, index) => {
      if (index < realIndex) {
        bullet.classList.add("completed");
      } else {
        bullet.classList.remove("completed");
      }
    });
  };

  const slides = testimonials
    .filter((testimonial) => testimonial !== null)
    .map((testimonial, index) => {
      const { company, companyLogo, name, position, photo, text } =
        testimonial as TestimonialWithSlug;

      return (
        <SwiperSlide key={index} style={{ height: "auto", display: "flex" }}>
          <div className="flex flex-col px-20 pb-24 pt-20 max-md:px-6 max-md:pb-20 max-md:pt-10">
            <p className="heading-4 h-[23rem] pb-4 max-md:h-auto max-md:pb-12">{text}</p>
            <div className="mt-auto flex items-center justify-between">
              <div className="flex items-center gap-8 max-md:flex-col max-md:items-start max-md:gap-6">
                <div className="flex gap-[-2rem]">
                  {photo && (
                    <img
                      src={photo.src}
                      className="size-20 overflow-hidden rounded-full max-md:size-10"
                      alt={name}
                      loading="lazy"
                    />
                  )}
                  <img
                    src={companyLogo.src}
                    className="z-1 relative size-20 overflow-hidden rounded-full max-md:size-10"
                    alt={name}
                    style={{ marginLeft: photo ? "-1.5rem" : "0" }}
                    loading="lazy"
                  />
                </div>
                <div className="max-md:mt-auto">
                  <p className="heading-5">{name}</p>
                  <p className="text-overline-small pt-1 text-dark-80">{`${position} · ${company}`}</p>
                </div>
              </div>

              {testimonial?.slug && (
                <div className="max-md:hidden">
                  <ExternalLink href={`/case-studies/${testimonial?.slug}`}>
                    case study
                  </ExternalLink>
                </div>
              )}
            </div>
          </div>
        </SwiperSlide>
      );
    });

  const swiperSettings = {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 24,
    speed: 1000,
    autoplay: disableMotion
      ? false
      : {
          delay: 5000,
          disableOnInteraction: false,
        },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index: number) {
        return `<span class="swiper-pagination-bullet inline-flex h-1 flex-1 bg-white  ${
          index === activeIndex ? "bg-opacity-100" : ""
        }"></span>`;
      },
    },
    onSlideChange: handleSlideChange,
  };

  return (
    <Swiper {...swiperSettings} style={{ alignItems: "stretch", width: "100%" }}>
      {slides}
      <div className="swiper-pagination absolute bottom-0 left-0 z-10 flex w-full gap-4 px-20 pb-8 max-md:gap-2 max-md:px-6 max-md:pb-10"></div>
    </Swiper>
  );
};
