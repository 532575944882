import React from "react";
import "./style.css";

interface ExternalLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({ children, ...props }) => {
  return (
    <a
      {...props}
      rel="noreferrer noopener"
      className="external-link group relative inline-flex items-center gap-2.5 rounded-full border border-zinc-500 py-2.5 pl-5 pr-2.5 shadow-button transition-all duration-200 ease-in-out hover:border-fluxonOffWhite active:shadow-button-active"
    >
      <span className="text-overline text-nowrap text-center text-fluxonOffWhite">{children}</span>
      <span
        className="inline-flex size-8 items-center justify-center overflow-hidden rounded-full bg-fluxonOffWhite bg-opacity-20 p-2 transition-colors duration-300  ease-in-out group-hover:bg-opacity-40 group-active:bg-opacity-60 
      "
      >
        <i className="external-link__icon ph ph-arrow-up-right text-[1rem]"></i>
      </span>
    </a>
  );
};
