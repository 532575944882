import type { CaseStudyTestimonial } from "~/types/caseStudy";

import {
  braintrustCaseStudy,
  marketingEvolutionCaseStudy,
  zapierCaseStudy,
} from "~/data/caseStudies";

import {
  CompanyLogoCliently,
  CompanyLogoGambit,
  CompanyLogoStripe,
  QuotePhotoCliently,
  QuotePhotoGambit,
  QuotePhotoStripe,
} from "../images";

export interface TestimonialWithSlug extends CaseStudyTestimonial {
  slug?: string;
}

export const testimonials: (TestimonialWithSlug | null)[] = [
  braintrustCaseStudy?.testimonial
    ? { ...braintrustCaseStudy.testimonial, slug: braintrustCaseStudy.slug }
    : null,

  zapierCaseStudy?.testimonial
    ? { ...zapierCaseStudy.testimonial, slug: zapierCaseStudy.slug }
    : null,

  marketingEvolutionCaseStudy?.testimonial
    ? { ...marketingEvolutionCaseStudy.testimonial, slug: marketingEvolutionCaseStudy.slug }
    : null,

  {
    company: "Stripe",
    companyLogo: CompanyLogoStripe,
    name: "William Watkins",
    position: "Solutions Architect",
    photo: QuotePhotoStripe,
    text: `"The Fluxon team's knowledge and experience were invaluable in delivering our product. Their professionalism was exceptional."`,
  },
  {
    company: "Gambit",
    companyLogo: CompanyLogoGambit,
    name: "Riaz Lalani",
    position: "Founder",
    photo: QuotePhotoGambit,
    text: `“The Fluxon team was critical in bringing our vision to reality. They lent expertise, professionalism and first-class engineering to the project, enabling us to launch full-featured mobile and web apps for Gambit’s Alpha launch.”`,
  },
  {
    company: "Cliently",
    companyLogo: CompanyLogoCliently,
    name: "Spencer Farber",
    position: "Ceo",
    photo: QuotePhotoCliently,
    text: `“Fluxon delivered beautiful and scalable custom integrations that have been vital to our growth. They also understand the unique challenges startups face and were with us every step of the way.”`,
  },
];
